import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./mapzones.css";
import gsap from "gsap";
import { useRef } from "react";
// import styled from "styled-components";

const MapSIZones = ({ data, goTo }) => {
  const siRef = useRef();
  const q = gsap.utils.selector(siRef);

  const [hoverName, setHoverName] = useState("");

  const clickHandler = (event) => {
    return goTo(event);
  };

  useEffect(() => {
    gsap.set(q("circle"), {
      opacity: 0,
    });
    gsap.to(q("circle"), {
      opacity: 1,
      duration: 2,
      delay: 1,
      stagger: 0.1,
    });
    gsap.set(q("[id*='name']"), {
      opacity: 0,
    });
    gsap.set(q("#tags"), {
      opacity: 1,
    });
  }, []);

  const getHoverName = (id) => {
    if (id === "176_177") {
      //Chathams
      return "Chatham Islands";
    }
    return data.filter((rec) => parseFloat(rec.number) === parseFloat(id))[0]
      .nameCommon;
  };

  const hoverHandler = ({ currentTarget }) => {
    setHoverName(getHoverName(currentTarget.id));
    try {
      gsap.to(q(`#dot${currentTarget.id}`), {
        fillOpacity: 0,
      });
    } catch (e) {
      // do nothing
    }
    // gsap.to(q(`[id*='name${currentTarget.id}']`), {
    //   opacity: 1,
    // });
  };

  const unhoverHandler = ({ currentTarget }) => {
    setHoverName("");
    try {
      gsap.to(q(`#dot${currentTarget.id}`), {
        fillOpacity: 1,
      });
    } catch (err) {
      //do nothing
    }
    // gsap.to(q(`[id*='name${currentTarget.id}']`), {
    //   opacity: 0,
    // });
  };

  return (
    <g
      cursor={"pointer"}
      ref={siRef}
      transform="scale(0.345) translate(26,6.5)"
    >
      {/*<g id="tags" transform="translate(-25,-5)">*/}
      {/*  <path*/}
      {/*    id="name140bg"*/}
      {/*    d="m 170.3,10.21 c -0.4,0 -0.8,0.91 -0.8,2.04 v 3.06 c 0,1.13 0.4,2.04 0.8,2.04 h 13.1 l 1.9,3.73 2.1,-3.73 h 16.1 c 0.4,0 0.8,-0.91 0.8,-2.04 v -3.06 c 0,-1.13 -0.4,-2.04 -0.8,-2.04 z"*/}
      {/*  />*/}
      {/*  <text*/}
      {/*    id="name140"*/}
      {/*    x="271.6"*/}
      {/*    y="235.7"*/}
      {/*    transform="matrix(0.2646,0,0,0.2646,99.41,-46.83)"*/}
      {/*  >*/}
      {/*    Golden Bay*/}
      {/*  </text>*/}
      {/*</g>*/}
      <g id="dots" fill="red" transform="translate(-20,0)">
        <circle id="dot140" cx="180.1" cy="14.87" r="1.756" />
        <circle id="dot141" cx="185.9" cy="29.74" r="1.756" />
        <circle id="dot142" cx="177.6" cy="48.33" r="1.756" />
        <circle id="dot143" cx="199.5" cy="38" r="1.756" />
        <circle id="dot144" cx="214" cy="26.02" r="1.756" />
        <circle id="dot145" cx="218.1" cy="34.17" r="1.756" />
        <circle id="dot146" cx="215.2" cy="51.22" r="1.756" />
        <circle id="dot147" cx="190" cy="74.77" r="1.756" />
        <circle id="dot148" cx="167.7" cy="97.9" r="1.756" />
        <circle id="dot149" cx="173.5" cy="113.2" r="1.756" />
        <circle id="dot150" cx="176.3" cy="120.4" r="1.756" />
        <circle id="dot151" cx="180.8" cy="125.2" r="1.756" />
        <circle id="dot152" cx="182.7" cy="130.8" r="1.756" />
        <circle id="dot153" cx="157" cy="116.1" r="1.756" />
        <circle id="dot154" cx="141" cy="127.6" r="1.756" />
        <circle id="dot155" cx="129.3" cy="154.9" r="1.756" />
        <circle id="dot156" cx="125.6" cy="169.4" r="1.756" />
        <circle id="dot157" cx="108.5" cy="144.5" r="1.756" />
        <circle id="dot158" cx="113.3" cy="175.7" r="1.756" />
        <circle id="dot159" cx="102" cy="189.2" r="1.756" />
        <circle id="dot160" cx="114" cy="211.5" r="1.756" />
        <circle id="dot161" cx="96.25" cy="214" r="1.756" />
        <circle id="dot162" cx="82.72" cy="163.2" r="1.756" />
        <circle id="dot163" cx="59.9" cy="176.8" r="1.756" />
        <circle id="dot164" cx="41.72" cy="193.7" r="1.756" />
        <circle id="dot165" cx="76.83" cy="218.5" r="1.756" />
        <circle id="dot166" cx="52.46" cy="221.4" r="1.756" />
        <circle id="dot167" cx="35.11" cy="222.2" r="1.756" />
        <circle id="dot168" cx="66.5" cy="253" r="1.756" />
        <circle id="dot169" cx="42.55" cy="262.3" r="1.756" />
        <circle id="dot170" cx="54.33" cy="256.5" r="1.756" />
        <circle id="dot171" cx="13.63" cy="251.1" r="1.756" />
        <circle id="dot172" cx="15.28" cy="197.4" r="1.756" />
        <circle id="dot173" cx="98.72" cy="119" r="1.756" />
        <circle id="dot174" cx="149.9" cy="73.94" r="1.756" />
        <circle id="dot175" cx="165.2" cy="40.89" r="1.756" />
        <circle id="dot176_177" cx="50.81" cy="69.6" r="1.756" />
      </g>
      {hoverName && (
        <g id="hoverName">
          <text
            x={150}
            y={270}
            style={{ fontSize: "smaller" }}
            textAnchor={"middle"}
          >
            {hoverName}
          </text>
        </g>
      )}
      <g id="zones" fillOpacity={0} transform="translate(-25.2,-6.5)">
        <path
          id="155"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M127.4782 150.3773c-10.3194.1852 2.9106 27.0156 11.907 18.3897 1.323-4.366.5292-7.0384 3.4398-11.5895-5.0274-1.0055-8.9964-4.657-13.7592-6.6415a8.077 8.077 0 0 0-1.5876-.1587zm15.6114 11.563c-.2646.0264-.2646.0529-.2646.1058l-.2646 1.8522c0 .053 0 .1059.2646.1059l12.4362 1.4553c.2646.0264.2646-.0265.2646-.0794l.2646-1.8522c0-.053 0-.1059-.2646-.1059l-12.4362-1.4817z"
        />
        <path
          id="169"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M42.6 266.166c-3.3 5.3-8 11.7-9.4 15.1 7.5 4.2 6.5-4.2 12.3-3.1 7.2-1 16.1-5.1 9.1-13.4-1.6-4.1-10.7-13.3-13.8-4.3 0 2.1.6 4.1 1.8 5.7z"
        />
        <path
          id="171"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M12.2 264.866c-.5 6.4 15.4 10.7 16.2-.4 3.4-14.3-15.6-24.9-20.4-7.7 1.8 4.6 1.1 5.4 4.2 8.1z"
        />
        <path
          id="166"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M57.3592 203.1914c-1.0584 0-1.8522.4763-2.3814 1.8522-12.1716 7.7528-3.969 19.9244-8.4672 31.4874-.7938 4.3395.2646 6.0594 1.8522 6.1652.2646 1.032 5.5566-.4763 6.8796-.635 1.8522 3.4927 8.4672 3.5985 3.1752 8.4407 7.6734 6.8531 7.4088-10.1342 11.6424-15.3468-9.261-8.1497-6.8796-19.4216-5.292-30.3496-2.646 1.3759-5.292-1.614-7.4088-1.614z"
        />
        <path
          id="172"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M48.892 160.1145c-4.7628-.1852-10.0548 7.2236-6.615 12.939-4.4982-5.3714-3.4398 4.8686-6.3504 1.4288 2.1168 4.604-2.9106 2.7783-2.1168 7.7528-2.3814-4.8157-3.969.7673-4.4982 3.8896-.7938-4.6834-7.6734 1.4289-4.7628 3.8367-6.0858-2.1697 3.1752 6.8267-4.2336 1.0849 4.4982 7.885-1.0584-.7938-2.3814 5.9535-2.9106 0-1.5876 4.0219 4.2336 2.5401 1.0584-2.646 5.5566 6.2181.7938 2.7519-9.7902-5.4772 5.0274 10.187-2.646 3.572.2646-2.1167-8.7318-7.0912-5.0274-.2116-1.0584.0265-7.4088 6.615-1.323 6.2181 8.4672-5.0803 1.8522.979-2.646 3.1752 6.615-1.0055 3.7044-.6615.2646 3.8896 6.8796-.7938 2.1168 1.8787-1.8522 1.9845-6.3504.344-5.0274 12.0658-.2646 5.5037 4.4982-5.0803-3.969 12.9654 4.4982 3.0958 7.6734-4.3394-2.3814 4.1807-4.4982 6.3504 6.3504.8732 12.4362 1.3495 18.7866 2.7519 4.7628-3.7309-2.9106-18.4162-3.969-26.0367 2.3814-12.0922 5.8212-26.8833 18.7866-32.678 9.261 2.5665 8.9964-5.9271 12.7008-11.7748l-1.5876-2.249c-1.0584-4.075-3.1752-5.7154-5.292-5.7684z"
        />
        <path
          id="161"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M85.936 203.4031c.5292 1.3495.7938 2.699 1.0584 4.0748-.2646.4499 0 1.0849.5292 1.4024.7938 3.1223 2.1168 6.0858 4.4982 8.4143-1.0584 4.313-.2646 8.1232 1.0584 11.854 1.8522 3.8103 6.0858 6.7738 8.7318 10.2401l1.323-.4763 1.5876-.7673c11.3778-5.345 2.3814-16.4052 4.2336-25.6133-6.3504-1.7199-16.6698-3.3604-23.0202-9.1287zm25.4016 32.4664c.2646.1059.2646.1059 0 0z"
        />
        <path
          id="165"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M85.4068 201.6303c-3.1752 0-6.0858.5292-8.9964 1.1907-13.7592-2.1168-11.907 11.7218-12.9654 21.0357 6.3504 6.5885 8.9964 13.1506 3.4398 21.3267-2.1168 6.4298 1.323 7.1178 4.7628 5.3185 1.0584 7.2236 8.9964 2.0903 11.907 3.7044 6.0858-1.7464 10.8486-1.9845 15.6114-7.2765 7.938-8.2555-6.8796-11.3249-6.8796-18.813-.2646-9.261-6.3504-16.6699-6.8796-26.4865z"
        />
        <path
          id="160"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M124.0384 211.8703c-1.8522-.0265-3.969.5027-5.8212 1.5611-5.5566 2.5931-10.584 8.9435-6.8796 14.8706 1.8522.0793 8.7318-6.7738 13.7592-6.5886.2646-2.7254-1.0584-3.6515-1.0584-9.8431zm5.0274 6.3504c-1.8522 4.4717 5.292 1.958 7.938 3.916 5.8212 1.5083 8.2026-1.4552 1.5876-2.6195-3.4398.2382-6.3504-.2116-9.5256-1.2965z"
        />
        <path
          id="167"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M46.1 215.366c2.4 4.3 2.1 8.7.6 13.2-1.6 4.9-1.1 10-.9 15-8.1 3.4-2.7-17.9-6.4-7.4-5.8-.3-13.7 0-7.9-7.6 4.3-2.5 5.8-9.2 7.2-1.9-4.2-11.4 2.5-6 7.4-11.3z"
        />
        <path
          id="159"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M102.8704 174.4823C95.4616 176.996 93.6094 186.31 85.936 189.3c2.9106 4.022 1.8522 8.3614.5292 12.7008 4.7628 2.3285 8.4672 7.3824 14.0238 7.6205 11.907 3.2281 8.4672-3.8896 9.5256 8.9435 4.2336-8.229 17.199-4.366 18.522-13.6534-6.0858-12.3039-18.2574-19.21-25.6662-30.429z"
        />

        <path
          id="163"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M63.7096 164.9567c-2.9106.8732-6.615 1.5612-8.2026 4.657-8.2026 11.1926-6.0858 37.1234 12.1716 33.7894 6.8796-2.011 21.6972.4498 19.0512-10.1077-1.323-3.5457-3.7044-8.3614-7.1442-4.604-10.584-6.5092-5.8212-6.0859-15.876-23.7347z"
        />
        <path
          id="158"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M110.2792 165.6447c-1.0584.2381-2.1168.4763-3.4398.7144-9.5256.7144-7.938 2.8048-1.8522 9.6579 7.938 9.3668 17.4636 17.6753 24.8724 27.3861-1.323-6.5885 3.969-13.7063 6.615-18.0193-6.8796-2.9106-23.0202-9.6314-26.1954-19.7391zm26.46 24.5549c-1.323 0-2.646 1.0584-1.5876 2.0903.2646.291 1.5876.1323 2.3814.5557 1.5876 1.3759 6.8796 2.249 9.5256 1.5611 1.323-.3704 1.8522-2.3814.5292-2.646-.2646-.053-.5292-.0794-.7938-.1323v-.053c0 .0265 0 .053-.2646.053-.5292-.0265-1.0584-.053-2.1168-.053.2646-.1058.2646-.2116.2646-.3439-.2646.1323-.2646.2381-.2646.344h-.5292c-.5292-.2117-4.4982-1.2436-6.615-1.376h-.5292z"
        />
        <path
          id="156"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M126.6844 165.6447c-.7938 2.0374-1.323 4.2071-3.1752 5.6624-8.9964 7.012 8.7318 10.584 13.7592 12.3304 7.6734-11.219-3.7044-12.5156-10.584-17.9928z"
        />
        <path
          id="164"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M45.1876 179.8802c-2.1168.0529-4.4982 1.1377-6.8796 3.625-9.261 9.5256-15.3468 24.502-10.8486 37.388.5292 11.6688 9.7902 4.8157 9.7902-2.6725 2.1168-.3175 5.5566.2646 6.615-1.9316 9.261-7.5146 15.6114-15.532 6.8796-26.3012 1.323-5.8741-1.5876-10.2136-5.5566-10.1077z"
        />
        <path
          id="162"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M91.228 146.5935c-8.9964 4.1277-25.9308 11.51-25.4016 21.7501 5.0274 4.4982 8.7318 18.3103 12.9654 18.2045 2.646 1.958 4.2336-.5821 5.292-2.6725-.2646.5292-.2646 1.1643-.5292 1.958 2.1168 8.917 13.23-10.822 18.7866-12.0657-7.938-7.0384-13.4946-15.5056-11.1132-27.1744zm8.9964 25.0576c0 .1323 0 .1323 0 0z"
        />
        <path
          id="152"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M193.6 132.466c-3.1-.1-7.5 1.9-7.7 1.5l-4.2-.5c-.8 5 3 5.8 5.4 8.2 2.4 2.2 7.7.8 9.1-1.3 3.5-5.6 1.2-7.9-2.6-7.9z"
        />
        <path
          id="151"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M187.5424 129.3151c-6.8796-.0265-7.1442 4.0484-3.969 3.8896l3.1752.291c.2646-.1587.5292-.3439 1.0584-.5556 3.7044-.1852 5.5566-1.5347 4.7628-2.1168-1.0584-.688-5.0274-.9525-4.2336-1.5082h-.7938z"
        />
        <path
          id="150"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M185.6973 123.1349c-4.657.2117-9.4727.8996-7.0648 5.9006 2.5666 6.218 7.1177-3.1488 7.0648-5.9006zm3.7573 2.99c-1.9051.0529-1.6934 1.2965-1.667 1.4024.1323.7938 10.7692 1.958 11.7218 1.958 5.5566.5027 8.8641 1.032 10.4252.8996 2.2491-.1587 1.323-1.323.3176-1.5876-4.2601-1.1377-14.6324-2.1168-20.1361-2.646h-.2381c-.0794 0-.1588-.0264-.2117-.0264h-.2117z"
        />
        <path
          id="150"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M188.4 132.766z"
        />

        <path
          id="157"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M122 121.166c6.9 12.6-.8 25.9 2.3 38.6 3.5 9.3-5.3 20.7-12.1 9.1-2.6-6.5 1.7-6.8-4.7-3.8-14 10.9-22-16.6-10.9-20.7 9.3-6.7 9.3-6.1 14.7-14.6 2.7-3.7 6.4-6.9 10.7-8.6z"
        />
        <path
          id="148"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M159.4948 91.2127c-12.1716 11.3778 4.4982 19.21 8.4672 30.3496.5292-4.5511 5.8212-6.4562 10.8486-7.9115 5.0274.1058.2646.5556 7.938 1.4288 1.5876-1.0055 2.3814-3.7308 4.4982-5.2655 3.7044-2.4873 8.9964-4.1542 11.907-7.6734-5.0274 1.958-8.4672-3.5721-13.7592-.397-11.1132-.6085-18.522-10.2135-29.8998-10.531zm-2.3814.5821c0 .0794 0 .0794 0 0zm30.9582 25.534c-.2646.1058-.2646.1058 0 0z"
        />
        <path
          id="149"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M186.7 115.866c1.6 13.4-29.2 5.4-10.3-.4 3.5-.4 6.9.3 10.3.4z"
        />
        <path
          id="154"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M138.3268 111.6398c-2.3814 0-4.4982 1.2701-6.8796 4.5247-12.4362 1.4553-6.615 10.4781-5.0274 19.2629-1.323 4.7363-1.0584 9.1816-1.323 11.3513 4.2336 3.5456 11.1132 5.6095 15.6114 7.9115 6.0858 1.2437 3.969.688 10.3194-5.5036 7.938-.0794 13.7592-8.7848 20.6388-7.5676-2.3814-7.5676-12.7008-8.7583-16.4052-15.1087-4.7628-4.9215-10.584-14.8176-16.9344-14.8705z"
        />
        <path
          id="153"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M153.4 101.466c6.2 9.9 13.7 21.5 22.9 23.4.5 4.5 9 14.1.6 10.9-2.7 9.7-6.8 2.3-6.2.3-2.4-.1-3.6-2.2-8.5-6.1-4.9-3.8-8.4-5.5-11.2-9.3-4.3-4.2-3.3-10.8-11.4-10.8 2-5.5 8.8-6.8 13.8-8.4z"
        />
        <path
          id="147"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M201.8308 54.8567c-8.9964.2116-12.7008 14.3942-20.1096 19.3158-5.0274 4.3659-28.0476 12.1451-17.4636 17.0402 10.3194 2.9106 18.7866 11.3778 29.6352 8.4672 11.1132 7.4882 13.23-6.6944 17.199-13.7592 6.3504-2.9106-.2646-7.25 3.969-6.5092 2.1168 3.4663 11.6424-7.9115 7.1442-13.2564-4.7628 2.1168-3.969 4.9745-7.6734.1323-4.2336-2.355-7.938-8.4937-11.3778-11.3514-.2646-.0529-.7938-.0793-1.323-.0793z"
        />
        <path
          id="173"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M150.2 101.766c-11.3 3.3-16.7 16-29 18.2-6.4 4.5-13.2 12.4-15.9 14.3-4.8.3-4.9 9.9-14.8 11.4-14 3.9-21.8 18.5-34.9 21.5-2.1-3.5-6.8-7.1-7.2-10.5 4.6-4.6 8.3-10.4 15-11.8 11.1 3.1 18.7-12.4 29.4-15.3 7.2-8.6 18.6-14.9 24.7-22.4 10.9-.8 15.2-15.77 23.2-16.82 2.7 4.23 6.9 7.22 9.5 11.42"
        />
        <path
          id="174"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M161.6116 61.895c-2.646-.0264-5.292.8732-7.6734 3.7044-1.5876 4.0749-12.4362 13.1771-11.1132 16.3523-.7938 1.376-1.8522 3.1223-2.9106 5.3714.7938 3.5985 7.938 11.0338 11.907 13.6533 4.7628-2.5666 6.615-9.208 6.8796-13.7327 2.646-.7144 5.292-2.302 7.1442-5.4508 8.2026-3.8896 20.3742-9.4726 8.7318-18.4426l-2.1168 1.9316c-3.4398-1.2436-7.1442-3.334-10.8486-3.3869z"
        />
        <path
          id="176_177"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M43.0708 49.6705H38.308s-.2646.053-.2646.1058v4.3395c0 .0529.2646.1058.2646.1058h1.0584c1.0584.6615 2.1168.635 2.9106 0h1.5876c.5292.4763 1.5876.3175 2.646 0h5.5566a3.979 3.979 0 0 0 .7938 0h8.4672c1.0584.3175 2.646.2646 3.969 0h9.5256V49.6705h-31.752zm12.7008 12.3304c-5.0274 0-9.5256 1.1377-14.2884 3.4662-5.292 7.5676 6.615 4.366 9.261 2.1168v.053c3.969 6.9854-7.4088 11.1131 1.8522 15.9818 6.615-2.3285 11.1132-5.4772 8.2026-9.6844-.7938-4.948 8.2026-12.3039 2.3814-11.2984-2.646-.4498-5.0274-.6615-7.4088-.635zm19.3158 9.4462c0 .1323 0 .1323 0 0zm-7.4088 12.3568c-5.0274 2.937 3.1752 9.208 1.0584.5557z"
        />
        <path
          id="142"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M182.7796 43.5582c-3.7044.1059-12.1716 16.8815-5.5566 12.1187-3.1752 2.8312-1.5876 5.3714.7938 9.8696 1.8522 12.4891 9.261 2.5137 12.4362-3.0429 1.323-2.5137 1.5876-1.7199 2.9106-3.0694 13.23-12.3568-14.0238-3.1222-10.3194-15.8495 0-.0265-.2646-.0265-.2646-.0265z"
        />
        <path
          id="175"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M172.1956 22.1786c-4.7628 9.3139-2.1168 24.3432-14.0238 31.5138-2.646.9526-3.4398 1.7464-3.1752 2.355-1.323.4498-1.8522.2646-1.8522-1.1643-5.8212-.9525-3.4398 10.0813-7.1442 13.5475-3.4398 14.4207 4.4982-2.8841 10.0548-5.1067 6.3504-8.0703 12.1716 3.3075 17.199-.1059.7938-3.0429.7938-5.583.2646-7.594 0-1.9845.7938-3.916 2.1168-5.5037 12.7008-7.0648 6.3504-24.5813-3.4398-27.9417z"
        />
        <path
          id="146"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M228.5 43.236c9.1 8.49 3.5 25.23-5.9 27.05 2.7-8.96-6.5.46-5.4-4.83-4.7.24-15.1-13.77-15-12.35 8.3-1.43 17.7-8.79 24-8.9.4-1 1.5-.67 2.3-.97z"
        />
        <path
          id="143"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="m213.4732 32.2863-2.1168.5821c-3.969 1.5876-5.0274 3.4398-5.0274 4.8686-6.0858 1.8787-2.646-2.0374-8.4672-3.5191-2.3814 5.3713-8.7318 16.6962.2646 18.7866 11.1132-.1059 25.6662-9.0758 15.3468-20.7182z"
        />
        <path
          id="141"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M199.9786 22.0727c-4.2336.7938-7.4088 3.7838-10.0548 6.7209-7.938 2.3814-9.261 27.8359 2.1168 19.3422 3.969-3.7308 1.0584-9.8166 4.4982-13.4417 2.3814-3.572.2646-8.7847 3.4398-12.6214z"
        />
        <path
          id="145"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M229.6 33.706c-6.3 3.48 3 3.45 8.1-.21 6.3-.41-4.6 7.04-12.8 10-6.3 2.28-10.7 1.5-6.5-5.25 3.1-2.72 7.3-3.66 11.2-4.54z"
        />
        <path
          id="144"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M227.2324 17.482c-5.8212.7699-3.1752 4.7495 0 0zm0 7.1573a6.8267 4.0537 0 0 0-6.8796 3.5721c-1.323.979-3.1752 1.2966-5.292 1.2172-5.5566 8.4407 9.7902 9.2345 12.1716 3.3075a6.8267 4.0537 0 0 0 5.0274-1.376c.5292.2647.7938 0 1.323-1.1906a6.8267 4.0537 0 0 0 .5292-1.4818 6.8267 4.0537 0 0 0-.2646-.4498c.2646-.5557.2646-1.1907.2646-1.9316-.5292-.2646-.7938-.3704-1.323-.3704l-.5292.1058a6.8267 4.0537 0 0 0-5.0274-1.4024z"
        />
        <path
          id="139.5" // Part 3
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M115.3066 16.7199c-.2646 0-.5292.0264-.7938.0555H89.6404v.0265-.0265c-.5292 2.7333-.2646 4.0458 0 4.477v2.1433h58.212v-6.6203h-32.2812c0-.0344-.2646-.0529-.2646-.0555z"
        />
        <path
          id="140"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          d="M189.3 6.546c-6.1 10.74 6.4 11.81 10.8 13.96-8.2 2.47-17.3 21.34-22.3 4.66-.9-10.41-11.4 3.01-6.9-6.72 5.9-3.67 11.2-10.82 18.4-11.9z"
        />
        <ellipse
          id="168"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          cx="589.1"
          cy="178.5"
          rx="7.848"
          ry="5.215"
          transform="matrix(.9886 .1494 -.1494 .9886 -485.6 -5.234)"
        />
        <ellipse
          id="170"
          onClick={clickHandler}
          onMouseOver={hoverHandler}
          onMouseLeave={unhoverHandler}
          cx="400.7"
          cy="483.4"
          rx="4.798"
          ry="6.899"
          transform="matrix(.8966 -.443 .3845 .923 -485.6 -5.234)"
        />
      </g>
    </g>
  );
};

MapSIZones.propTypes = {
  goTo: PropTypes.func.isRequired,
};

export default MapSIZones;
