const allZones = require("../data/zones.json");
const allSpeakerInfo = require("../data/speakers.json");

// General utils

export const isArrPresent = (myArr) => {
  // no val, not array, or empty array
  if (!myArr || !Array.isArray(myArr) || !myArr.length) {
    return false;
  }
  // If array content is arrays
  if (
    myArr &&
    Array.isArray(myArr) &&
    myArr.length &&
    myArr.reduce((prev, curr) => prev || Array.isArray(curr), false)
  ) {
    // return true if any of the inner arrays are of non-zero length, else false
    return myArr.reduce((prev, curr) => prev || isArrPresent(curr), false); // #recursion
  }
  // return true if array length > 0 and inner items are not arrays, else false
  return myArr && Array.isArray(myArr) && myArr.length;
};

// Search Utils

/**
 * Create an object containing only the info needed for searching.
 * Fusejs has an undocumented limit on the size of the index it can search. Beyond that it returns nonsense for no obivuos reason.
 * This method ensures that the index stays as small as possible.
 *
 * If you want to be able to search additional fields, add them here and specify them in the fusejs options in SearchBox.js
 * You'll also need to extend getMatchingLocalities (below) to match locality records on the new fields
 * @param {object[]} fullData - the complete data object array
 * @returns {object[]} - an array of objects with a subset of the data used for search
 */
export const getIndexData = (fullData = allZones) => {
  return fullData.map((zone) => ({
    number: zone.number,
    nameCommon: zone.nameCommon,
    localities: zone.localities.map((l) => ({
      uniqueId: l.uniqueId,
      name: l.name,
      altSpellings: l.altSpellings,
      speaker: l.speaker,
      altNames: l.altNames?.map((alt) => ({
        name: alt.name,
        altSpellings: alt.altSpellings,
      })),
      types: l.types?.map((t) => ({
        name: t.name,
      })),
    })),
  }));
};

// Zone filtering
/**
 * FuseJS returns a list of matches, and accompanying metadata. Can be useful, but ultimately not needed.
 * Because FuseJS is working on a subset of the data, this re-injects the full data objects for
 * display purposes
 * @param fuseResult
 * @param {object[]} allData - Defaults to the full zone data set. mostly for testing/DI
 * @returns {*[]}
 */
export const getResultDataBasedOnFuseResult = (
  fuseResult,
  allData = allZones
) => {
  const output = [];
  fuseResult.forEach((result, index) => {
    const matches = result.matches.map((match) => match.value);
    // return whole zone if the search matches on the whole zone name. A bit brittle.
    const zoneRecord = allData.find((z) => z.number === result.item.number);
    output[index] = { ...zoneRecord };
    if (
      matches.some(
        (match) =>
          removeSpecialCharacters(match).toLowerCase() ===
          removeSpecialCharacters(zoneRecord.nameCommon).toLowerCase()
      )
    ) {
      return (output[index].localities = zoneRecord.localities);
    }
    // otherwise return just matching search results
    const filteredLocalities = getMatchingLocalities(
      zoneRecord.localities,
      matches
    );
    return (output[index].localities = filteredLocalities);
  });
  return output;
};

export const hasMatchingAltName = (locality, matches) => {
  return (
    Array.isArray(locality.altNames) &&
    locality.altNames.reduce(
      (prev, curr) =>
        prev ||
        matches.includes(curr.name) ||
        hasMatchingAltSpelling(curr, matches),
      false
    )
  );
};
export const hasMatchingAltSpelling = (locality, matches) =>
  Array.isArray(locality.altSpellings) &&
  locality.altSpellings.reduce(
    (prev, curr) => prev || matches.includes(curr),
    false
  );
export const hasMatchingName = (locality, matches) =>
  matches.includes(locality.name);
export const hasMatchingSpeaker = (locality, matches) =>
  matches.includes(locality.speaker);
export const hasMatchingTypeName = (locality, matches) =>
  Array.isArray(locality.types) &&
  locality.types.reduce(
    (prev, curr) => prev || matches.includes(curr.name),
    false
  );

// decide which localities within a zone match the search result
export const getMatchingLocalities = (localities, matches) => {
  return localities.filter(
    (locality) =>
      hasMatchingName(locality, matches) || // exact top level locality name
      hasMatchingAltSpelling(locality, matches) || // no-special-characters version of the name
      hasMatchingAltName(locality, matches) || // matches an alternative pronunciation of a locality (with or without special characters)
      hasMatchingSpeaker(locality, matches) || // is spoken by a speaker matching the search term
      hasMatchingTypeName(locality, matches) // has a Kind/Type name matching (useful for english name matching)
  );
};

// Modal matching methods

// See Also records can be in any zone
const idRegex = /pn_(zo|pa)_(\d+)-(\d+)/;
export const getSeeAlsoRecordsByIds = (ids, allData = allZones) => {
  if (!isArrPresent(ids)) {
    return [];
  }
  const output = [];
  ids.forEach((id) => {
    if (!idRegex.test(id)) {
      // if incorrectly formatted id value is passed, quick escape
      console.log("Invalid ID: " + id);
      return; // was previously "throw new Error("Invalid ID: " + id)" but that isn't great for production;
    }
    // grab the bits we actually care about from the ID.
    const [, , zone, localityNum] = id.match(idRegex);
    const matchingZone = allData.find((z) => z.number === parseInt(zone));
    const matchingLocality = matchingZone?.localities.find(
      (l) => l.order === parseInt(localityNum)
    );
    if (!matchingLocality) {
      console.log("unable to find a match for ", id);
    } else {
      output.push({
        zoneNumber: zone,
        zoneName: matchingZone.nameCommon,
        ...matchingLocality,
      });
    }
  });
  return output;
};

// Super Script utilities

const getZoneRecordsByZoneId = (id, zoneData = allZones) => {
  return zoneData.filter((z) => z.number === parseInt(id))[0];
};

// Superscripts only match within the same zone.
export const getZoneSuperscriptRecordsById = (id, zoneNum, thisRecord) => {
  const zone = getZoneRecordsByZoneId(zoneNum);
  const superRecords = new Set();
  zone.localities.forEach((l) => {
    if (l?.supers?.includes(id) && l.order !== thisRecord) {
      superRecords.add(l);
    }
  });
  return Array.from(superRecords);
};

export const getAllSuperRecs = (ids = [], zoneNum, thisRecord) => {
  const idSet = new Set(ids);
  return Array.from(idSet).map((id) =>
    getZoneSuperscriptRecordsById(id, zoneNum, thisRecord)
  );
};

// Types
// Hugh had an enormous list of special characters he wanted to have included.
// Some of them are so weird that there's not even unicode values for them, so this is the workaround.
// This strips out any and all weird characters to plain english, for comparisons, search, etc.
const removeSpecialCharacters = (input) => {
  return input
    .replace(/\u0101/g, "a")
    .replace(/\u0100/g, "A")
    .replace(/\u0113/g, "e")
    .replace(/\u0112/g, "E")
    .replace(/\u012b/g, "i")
    .replace(/\u012a/g, "I")
    .replace(/\u014d/g, "o")
    .replace(/\u014c/g, "O")
    .replace(/\u016b/g, "u")
    .replace(/\u016a/g, "U")
    .replaceAll(/_k_/gi, "k")
    .replaceAll("ʰ", "h")
    .replaceAll(/_h_/gi, "h")
    .replaceAll("ᴴ", "H")
    .replaceAll(/_f_/gi, "f")
    .replaceAll("𝒇", "f")
    .replaceAll("𝙁", "F")
    .replaceAll(/_g_/gi, "g")
    .replaceAll("𝒈", "f")
    .replaceAll("𝙂", "F")
    .replaceAll(/_l_/gi, "l")
    .replaceAll("𝒍", "l")
    .replaceAll("𝙇", "L");
};

// No point displaying the type name if it's easily guessable from the locality name.
const displayTypeName = (type, localityName) => {
  if (!type.name) {
    return false;
  }
  const isName =
    removeSpecialCharacters(type.name).toLowerCase() ===
    removeSpecialCharacters(localityName).toLowerCase();
  // {Type} {Name} or {Name} {Type} are not displayed. E.g. Lake X, Y School
  const isEssentiallyName =
    removeSpecialCharacters(`${localityName} ${type.type}`).toLowerCase() ===
      removeSpecialCharacters(type.name).toLowerCase() ||
    removeSpecialCharacters(`${type.type} ${localityName}`).toLowerCase() ===
      removeSpecialCharacters(type.name).toLowerCase();
  return !isName && !isEssentiallyName;
};

// Some Kinds have prefixes. Why? no idea.
const prefixKindName = (prefix, main) => {
  if (prefix) {
    return `${prefix} ${main}`;
  }
  return main;
};

// Build out a single string with all the types and typenames, as needed.
// Easier to get them comma seperated this way.
// You can use conditional css :after to do the commas (look in SpeakerName for an example)
// if you would rather have each type as its own react element, but that was overly complicated for here.
export const getTypesString = (typesArr = [], localityName) =>
  typesArr
    .map(
      (rec) =>
        prefixKindName(rec.prefix, rec.type) +
        (displayTypeName(rec, localityName) ? ` (${rec.name})` : "")
    )
    .join(", ");

// Speaker Notes
export const getSpeakerNotesByName = (
  searchName,
  speakerInfo = allSpeakerInfo
) => {
  const matchingSpeaker = speakerInfo.find(
    (speaker) => speaker.name.toLowerCase() === searchName.toLowerCase()
  );
  return matchingSpeaker?.notes || [];
};

export const hasSpeakerNotes = (searchName) => {
  return isArrPresent(getSpeakerNotesByName(searchName));
};
